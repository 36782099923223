<template>
  <v-container id="dashboard" fluid tag="section">

  <v-form ref="ticketType">
    <base-material-card icon="mdi-ticket">
      <slot slot="title">Ticket Types</slot>
      <slot slot="rightButtons">
        <toggle-button text="Add a new Ticket Type" openIcon="mdi-plus"
         @toggle="showCreateTicketTypeToggle = !showCreateTicketTypeToggle" :showing="showCreateTicketTypeToggle"/>
      </slot>
      <p>Each event can have several ticket types.  For example, you could have Paid Tickets and Free Tickets.  If your event has several occurances (Event Times),
        then these tickes are available for each Event Time.
      </p>
      <div class="mt-3" v-if="showCreateTicketTypeToggle">
        <v-text-field label="New Ticket Type Name" v-model="newTicketTypeName" :rules="[utils.requiredRule]">
          <template v-slot:append-outer>
            <v-icon large color="success" @click="createTicketType">mdi-checkbox-marked-circle</v-icon>
            <v-icon large @click="showCreateTicketTypeToggle = false">mdi-close-circle</v-icon>
          </template>
        </v-text-field>
      </div>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-row class="mx-n1 underlinedRow" outline >
          <v-col cols="4">Name</v-col>
          <v-col cols="2" class="text-right">Total</v-col>
          <v-col cols="2" class="text-right">Sold</v-col>
          <v-col cols="2" class="text-right">Avail</v-col>
          <v-col cols="2" class="text-right">%Sold</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(ticketType, i) in ticketTypes" :key="i" @click="selectItem(ticketType)">
              <v-col cols="4">{{ticketType.name}}</v-col>
              <v-col cols="2" class="text-right">{{avail(ticketType, "quantity")}}</v-col>
              <v-col cols="2" class="text-right">{{avail(ticketType, "confirmedTickets")}}</v-col>
              <v-col cols="2" class="text-right">{{avail(ticketType, "availableTickets")}}</v-col>
              <v-col cols="2" class="text-right">{{percentSold(ticketType)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row class="mx-n1 underlinedRow" outline>
          <v-col cols="2">Name</v-col>
          <v-col cols="2">Total Tickets</v-col>
          <v-col cols="2">Confirmed</v-col>
          <v-col cols="2">Unconfirmed</v-col>
          <v-col cols="2">Available</v-col>
          <v-col cols="2">% Sold</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(ticketType, i) in ticketTypes" :key="i" @click="selectItem(ticketType)">
              <v-col cols="2">{{ticketType.name}}</v-col>
              <v-col cols="2">{{avail(ticketType, "quantity")}}</v-col>
              <v-col cols="2">{{avail(ticketType, "confirmedTickets")}}</v-col>
              <v-col cols="2">{{avail(ticketType, "unconfirmedTickets")}}</v-col>
              <v-col cols="2">{{avail(ticketType, "availableTickets")}}</v-col>
              <v-col cols="2">{{percentSold(ticketType)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </base-material-card>
  </v-form>

  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import axios from "axios";
  import Store from '@/services/Store.js';

  export default {
    name: 'Event',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
      SelectImageButton: () => import('./components/SelectImageButton'),
      SelectColours: () => import('./components/SelectColours'),
    },  

    data () {
      return {
        newTicketTypeName: "",
        event: {},
        boxOffice: {},
        availability: {},
        ticketTypes: [],
        user: {},
        showCreateTicketTypeToggle: false,
      }
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
    },

    created() {
      this.event.id = this.$route.params.id;
      this.load();
    },

    methods: {

      avail(ticketType, type) {
        var avail = this.availability[ticketType.id];
        if (avail) {
          return avail[type];
        }
      },

      percentSold(ticketType) {
        var avail = this.availability[ticketType.id];
        if (avail) {
          return Utils.formatPercent((avail.confirmedTickets + avail.unconfirmedTickets) / avail.quantity);
        }
      },

      async load() {
        this.event = await Api.post(this, "Event", "read", this.event);
        await this.readBoxOffice();
        await this.readTicketTypes();
      },

      async readTicketTypes() {
        this.ticketTypes = await Api.post(this, "TicketType", "list", this.event);
        var availabilities = await Api.post(this, "Event", "availability", this.event);
        this.availability = {};
        for (var avail of availabilities) {
          var existing = this.availability[avail.ticketTypeId];
          if (!existing) {
            existing = {};
            existing.confirmedTickets = 0;
            existing.unconfirmedTickets = 0;
            existing.quantity = 0;
            existing.availableTickets = 0;
          } 
          existing.confirmedTickets += avail.confirmedTickets;
          existing.unconfirmedTickets += avail.unconfirmedTickets;
          existing.quantity += avail.quantity;
          existing.availableTickets += avail.availableTickets;
          this.availability[avail.ticketTypeId] = existing;
        }
      },

      async readBoxOffice() {
        this.boxOffice.id = this.event.boxOfficeId;
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice, this.event);
      },

      async createTicketType() {
        if (! await Utils.validate(this.$refs.ticketType) ) {
          return;
        }
        var ticketType = {}
        ticketType.name = this.newTicketTypeName;
        ticketType.eventId = this.event.id;
        ticketType.boxOfficeId = this.boxOffice.id;
        await Api.post(this, "TicketType", "create", ticketType);
        this.newTicketTypeName = "";
        this.showCreateTicketTypeToggle = false;
        this.readTicketTypes();
      },

      selectItem(ticketType) {
        this.$router.push("/Dashboard/TicketType/" + ticketType.id)
      },
    }
  }
</script>
